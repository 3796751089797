import React, { useEffect, useState } from 'react';
import { refreshToken } from '../Token/refreshToken';
import { toast } from 'react-toastify';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FaChartLine } from 'react-icons/fa';
import './trends.css';

const Trends: React.FC = () => {
  interface Trend {
    keyword: string;
    url: string;
  }

  const [trends, setTrends] = useState<Trend[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const token = sessionStorage.getItem('ml_access_token');
  const selectedAccount = '7';

  useEffect(() => {
    // Função para atualizar o token
    const initialize = async () => {
      try {
        await refreshToken(selectedAccount, setLoading);
      } catch (error) {
        console.error('Erro ao atualizar o token:', error);
        toast.error('Erro ao atualizar o token');
      } finally {
        setLoading(false);
      }
    };

    initialize();
  }, [selectedAccount]);

  useEffect(() => {
    // Função para buscar tendências
    const fetchTrends = async () => {
      if (loading || !token) return; // Aguarda o token estar disponível e o loading ser falso
      try {
        const response = await fetch(
          `${import.meta.env.VITE_APP_API_URL}/mercadolivre/trends`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        
        if (!response.ok) {
          throw new Error("Erro ao buscar tendências");
        }

        const data = await response.json();
        setTrends(data);
      } catch (error) {
        console.error('Erro ao buscar tendências:', error);
        toast.error('Erro ao buscar tendências');
      }
    };

    fetchTrends();
  }, [loading, token]);

  return (
    <Container className="trends-container mt-5">
      <h1 className="text-center mb-4">Tendências de Busca no Mercado Livre</h1>
      <strong>
        Abaixo poderá conhecer quais são os 50 produtos mais buscados pelos
        usuários no Mercado Livre. As tendências são atualizadas semanalmente e
        poderá vê-las da seguinte maneira:
      </strong>
      <hr />
      <Row>
        {trends.map((trend, index) => (
          <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-4">
            <Card className="trend-card h-100 shadow-sm">
              <Card.Body className="d-flex flex-column align-items-center text-center">
                <FaChartLine size={30} className="text-primary mb-2" />
                <Card.Title className="trend-keyword mb-2">
                  {trend.keyword}
                </Card.Title>
                <Card.Text className="text-muted">
                  <a
                    href={trend.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="trend-link"
                  >
                    Ver no Mercado Livre
                  </a>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Trends;
