// ProductSearchLink.jsx
import React from 'react';
import PropTypes from 'prop-types';
import './ProductSearchLink.css';

interface ProductSearchLinkProps {
  produto: string;
}

const ProductSearchLink: React.FC<ProductSearchLinkProps> = ({ produto }) => {
  const handleSearchClick = () => {
    // Redireciona para a rota /pesquisaMercado?q={produto}
    window.open(`/pesquisaMercado?q=${encodeURIComponent(produto)}`, '_blank');
  };

  return (
    <button onClick={handleSearchClick} className="product-search-link info">
      Buscar Preço
    </button>
  );
};

ProductSearchLink.propTypes = {
  produto: PropTypes.string.isRequired,
};

export default ProductSearchLink;
