import React, { useEffect, useState, ReactNode } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faFileExcel,
  faFilePdf,
  faEdit,
  faTrashAlt,
  faInfoCircle,
  faExclamationTriangle,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

interface PermissionWrapperProps {
  screenId: string;
  permission: string;
  children: ReactNode;
}

interface PermissionButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  permission: string;
  variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info';
  icon?: 'plus' | 'fileExcel' | 'filePdf' | 'edit' | 'trashAlt' | 'infoCircle';
}

interface PermissionTableProps
  extends React.TableHTMLAttributes<HTMLTableElement> {
  permission: string;
}

interface PermissionModalProps {
  show: boolean;
  onHide: () => void;
}

const StyledModal = styled(Modal)`
  .modal-content {
    border-radius: 15px;
    border: none;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }

  .modal-header {
    background-color: #ffc107;
    color: #fff;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom: none;
  }

  .modal-body {
    padding: 2rem;
    text-align: center;
  }

  .modal-footer {
    border-top: none;
    justify-content: center;
  }
`;

const WarningIcon = styled(FontAwesomeIcon)`
  font-size: 4rem;
  color: #ffc107;
  margin-bottom: 1rem;
`;

const SmallButton = styled.button`
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
`;

const iconMap: { [key: string]: IconDefinition } = {
  plus: faPlus,
  fileExcel: faFileExcel,
  filePdf: faFilePdf,
  edit: faEdit,
  trashAlt: faTrashAlt,
  infoCircle: faInfoCircle,
};

const PermissionButton: React.FC<PermissionButtonProps> = ({
  children,
  permission,
  variant = 'primary',
  icon,
  className = '',
  ...props
}) => {
  const buttonClasses = `btn btn-${variant} ${className}`;
  const hasPermission = usePermissionCheck(permission);

  const button = (
    <SmallButton
      className={buttonClasses}
      {...props}
      disabled={!hasPermission || props.disabled}
    >
      {icon && <FontAwesomeIcon icon={iconMap[icon]} className="me-2" />}
      {children}
    </SmallButton>
  );

  if (!hasPermission) {
    return (
      <span
        className="d-inline-block"
        title="Você não tem permissão para esta ação - Contate o administrador para mais informações"
      >
        {button}
      </span>
    );
  }

  return button;
};

const PermissionTable: React.FC<PermissionTableProps> = ({
  children,
  permission,
  className = '',
  ...props
}) => {
  const hasPermission = usePermissionCheck(permission);

  return hasPermission ? (
    <table className={`table ${className}`} {...props}>
      {children}
    </table>
  ) : null;
};

const usePermissionCheck = (permission: string) => {
  const context = React.useContext(PermissionContext);
  return context.hasPermission(permission);
};

const PermissionContext = React.createContext<{
  hasPermission: (permission: string) => boolean;
}>({
  hasPermission: () => false,
});

const PermissionModal: React.FC<PermissionModalProps> = ({ show, onHide }) => {
  const navigate = useNavigate();

  const handleConfirm = () => {
    onHide();
    navigate('/');
  };

  return (
    <StyledModal
      show={show}
      onHide={handleConfirm}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>Acesso Negado</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <WarningIcon icon={faExclamationTriangle} />
        <p>Você não tem permissão para acessar esta tela.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleConfirm}>Entendi</Button>
      </Modal.Footer>
    </StyledModal>
  );
};

const PermissionWrapper: React.FC<PermissionWrapperProps> = ({
  screenId,
  permission,
  children,
}) => {
  const [userPermissions, setUserPermissions] = useState<string[]>([]);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const token = sessionStorage.getItem('authToken');
        const userId = sessionStorage.getItem('userId');
        const response = await axios.get<{ permissions: string[] }>(
          `${
            import.meta.env.VITE_APP_API_URL
          }/screens/${screenId}/permissions/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUserPermissions(response.data.permissions || []);

        if (!response.data.permissions.includes(permission)) {
          setShowModal(true);
        }
      } catch (error) {
        console.error('Erro ao obter as permissões do usuário:', error);
        setShowModal(true);
      }
    };

    fetchUserPermissions();
  }, [screenId, permission]);

  const hasPermission = (perm: string): boolean => {
    return userPermissions.includes(perm);
  };

  const handleModalClose = () => {
    setShowModal(false);
    navigate('/');
  };

  return (
    <PermissionContext.Provider value={{ hasPermission }}>
      {hasPermission(permission) ? children : null}
      <PermissionModal show={showModal} onHide={handleModalClose} />
    </PermissionContext.Provider>
  );
};

export { PermissionWrapper, PermissionButton, PermissionTable };
