// Top20.tsx
import React, { useState, useEffect } from 'react';
import {
  Container,
  Form,
  Spinner,
  Alert,
  Button,
  Image,
  Card,
  Row,
  Col,
} from 'react-bootstrap';
import ExcelJS from 'exceljs';

import axios from 'axios';
import { toast } from 'react-toastify';
import useFetchCategorias from '../../hooks/useFetchCategorias';
import { refreshToken } from '../Token/refreshToken';

import { CSSProperties } from 'react';

const cardStyle: CSSProperties = {
  width: '100%',
  maxWidth: '480px',
  minHeight: '680px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Sombra para o card
  borderRadius: '12px',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center', // Alinha o conteúdo ao centro
  justifyContent: 'space-between',
};

const imageContainerStyle: CSSProperties = {
  width: '100%',
  // height: '500px',
  // aspectRatio: '16/9', // Mantém uma proporção de aspecto fixa, pode ajustar para outras proporções
  overflow: 'hidden', // Oculta partes da imagem que ultrapassam o contêiner
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#f0f0f0', // Cor de fundo para caso a imagem não ocupe todo o espaço
};

const imageStyle: CSSProperties = {
  width: '100%',
  height: '100%',
};

const buttonStyle = {
  borderRadius: '8px',
  fontWeight: 'bold',
  marginTop: '10px',
};

const titleStyle = {
  fontSize: '1rem',
  fontWeight: 600,
  color: '#333',
};

interface ProductDetails {
  id: string;
  title: string;
  price: number;
  position: number;
  type: string;
  permalink?: string;
  thumbnail?: string;
  brand?: string;
  condition?: string;
  sold_quantity?: number;
  free_shipping?: boolean;
  location?: string;
}

const Top20: React.FC = () => {
  const { data: categorias, isLoading, isError, error } = useFetchCategorias();
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [top20Products, setTop20Products] = useState<ProductDetails[]>([]);
  const [loadingProducts, setLoadingProducts] = useState<boolean>(false);
  const token = sessionStorage.getItem('ml_access_token');
  const [, setLoading] = useState<boolean>(false);
  const selectedAccount = '7';

  // Função que executa ao carregar a página
  useEffect(() => {
    const initialize = async () => {
      try {
        setLoading(true);
        await refreshToken(selectedAccount, setLoading);
      } catch (error) {
        console.error('Erro ao atualizar o token:', error);
        toast.error('Erro ao atualizar o token');
      } finally {
        setLoading(false);
      }
    };

    initialize();
  }, [selectedAccount]);

  // Função para buscar os 20 produtos mais vendidos
  const fetchTop20Products = async () => {
    if (!selectedCategory) {
      toast.warning('Selecione uma categoria');
      return;
    }

    setLoadingProducts(true);
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_APP_API_URL}/mercadolivre/top20products`, // Chamada para o backend
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            category_id: selectedCategory,
          },
        }
      );

      if (response.status === 200) {
        const topProducts = await Promise.all(
          response.data.content.map(async (product: any) => {
            const details = await fetchProductDetails(product.id, product.type);
            return {
              id: product.id,
              position: product.position,
              type: product.type,
              title: details?.title || 'Título não disponível',
              price: details?.price || 0,
              permalink: details?.permalink,
              thumbnail: details?.thumbnail,
              brand: details?.brand || 'N/A',
              condition: details?.condition === 'new' ? 'Novo' : 'Usado',
              sold_quantity: details?.sold_quantity || 0,
              free_shipping: details?.free_shipping || false,
              location: details?.location || 'Localidade não informada',
            };
          })
        );
        setTop20Products(topProducts);
      } else {
        throw new Error('Erro ao buscar os produtos');
      }
    } catch (error) {
      console.error('Erro ao buscar os produtos:', error);
      toast.error('Erro ao buscar os produtos');
    } finally {
      setLoadingProducts(false);
    }
  };

  // Função que obtém detalhes dos produtos para exibição com base no tipo
  const fetchProductDetails = async (productId: string, type: string) => {
    let url = `https://api.mercadolibre.com/items/${productId}`;

    // Verifica o tipo para definir o endpoint correto
    if (type === 'PRODUCT') {
      url = `${
        import.meta.env.VITE_APP_API_URL
      }/mercadolivre/product-details/${productId}`;
    }

    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Lógica para extrair a localidade
      let location = 'Localidade não informada';
      if (response.data.seller_address) {
        const city =
          response.data.seller_address.city?.name || 'Cidade não informada';
        const state =
          response.data.seller_address.state?.name || 'Estado não informado';
        location = `${city}, ${state}`;
      } else if (response.data.buy_box_winner?.seller_address) {
        // Tratamento alternativo para produtos de catálogo (catalog_product_id)
        const city =
          response.data.buy_box_winner.seller_address.city?.name ||
          'Cidade não informada';
        const state =
          response.data.buy_box_winner.seller_address.state?.name ||
          'Estado não informado';
        location = `${city}, ${state}`;
      }

      // Retorna os detalhes relevantes
      return {
        title: type === 'PRODUCT' ? response.data.name : response.data.title,
        price: response.data.buy_box_winner?.price || response.data.price || 0,
        permalink: response.data.permalink,
        thumbnail: response.data.pictures
          ? response.data.pictures[0].url
          : response.data.thumbnail,
        sold_quantity: response.data.sold_quantity || 'Não informado',
        condition: response.data.condition === 'new' ? 'Novo' : 'Usado',
        free_shipping: response.data.shipping?.free_shipping ? 'Sim' : 'Não',
        location, // Adicionando a variável location processada
        brand:
          response.data.attributes?.find(
            (attr: { id: string }) => attr.id === 'BRAND'
          )?.value_name || 'Não informado',
      };
    } catch (error) {
      console.error(`Erro ao obter detalhes do produto ${productId}:`, error);
      return null;
    }
  };
  const exportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Top 20 Produtos');

    worksheet.columns = [
      { header: 'Posição', key: 'position', width: 10 },
      { header: 'Título', key: 'title', width: 30 },
      { header: 'Marca', key: 'brand', width: 15 },
      { header: 'Preço', key: 'price', width: 15 },
      { header: 'Frete Grátis', key: 'free_shipping', width: 15 },
      { header: 'Localidade', key: 'location', width: 25 },
      { header: 'Link', key: 'permalink', width: 50 },
    ];

    top20Products.forEach((product) => {
      worksheet.addRow({
        position: product.position,
        title: product.title,
        brand: product.brand,
        price: product.price,
        free_shipping: product.free_shipping ? 'Sim' : 'Não',
        location: product.location,
        permalink: product.permalink,
      });
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'Top_20_Produtos_MercadoLivre.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    toast.success('Exportação para Excel realizada com sucesso!');
  };

  return (
    <Container>
      <h1>Top 20 Produtos Mais Vendidos</h1>
      <p>
        Selecione uma categoria para visualizar os 20 produtos mais vendidos.
      </p>

      {isLoading ? (
        <Spinner animation="border" />
      ) : isError ? (
        <Alert variant="danger">Error: {error?.message}</Alert>
      ) : (
        <>
          <Form.Group controlId="categoriaSelect">
            <Form.Label>Selecione uma Categoria</Form.Label>
            <Form.Select
              aria-label="Selecione uma Categoria"
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value="">Selecione...</option>
              {categorias?.map((categoria) => (
                <option key={categoria.id} value={categoria.id}>
                  {categoria.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Button
            variant="primary"
            className="mt-3"
            onClick={fetchTop20Products}
            disabled={!selectedCategory || loadingProducts}
          >
            {loadingProducts ? 'Carregando...' : 'Buscar Top 20'}
          </Button>
          {top20Products.length > 0 && (
            <Button
              variant="success"
              className="mt-3 ms-3"
              onClick={exportToExcel}
            >
              Exportar para Excel
            </Button>
          )}

          {loadingProducts && <Spinner animation="border" className="mt-3" />}

          {!loadingProducts && top20Products.length > 0 && (
            <Row className="mt-4">
              {top20Products.map((product) => (
                <Col md={6} lg={4} key={product.id} className="mb-4">
                  <Card style={cardStyle}>
                    {product.thumbnail && (
                      <div style={imageContainerStyle}>
                        <Image src={product.thumbnail} style={imageStyle} />
                      </div>
                    )}
                    <Card.Body>
                      <Card.Title style={titleStyle}>
                        <strong>Posição {product.position}:</strong>{' '}
                        {product.title}
                      </Card.Title>
                      <Card.Text>
                        <strong>Marca:</strong> {product.brand}
                      </Card.Text>
                      <Card.Text>
                        <strong>Preço:</strong> R$ {product.price.toFixed(2)}
                      </Card.Text>
                      <Card.Text>
                        <strong>Frete Grátis:</strong>{' '}
                        {product.free_shipping ? 'Sim' : 'Não'}
                      </Card.Text>
                      <Card.Text>
                        <strong>Localidade do Vendedor:</strong>{' '}
                        {product.location}
                      </Card.Text>
                      {product.permalink && (
                        <Button
                          style={buttonStyle}
                          href={product.permalink}
                          target="_blank"
                          variant="success"
                          className="w-100"
                        >
                          Ver no Mercado Livre
                        </Button>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          )}
        </>
      )}
    </Container>
  );
};

export default Top20;
